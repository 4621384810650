<template>
  <v-card>
    <v-card-text class="py-2">
      <v-icon color="info" size="20"> mdi-information-outline </v-icon>
      Click
      <v-icon class="small">mdi-chevron-down</v-icon>/<v-icon class="small"
        >mdi-chevron-up</v-icon
      >
      to show/hide student names
    </v-card-text>
    <v-list
      v-for="(section, index) in studentsEnrolled"
      :key="`section-${index}`"
      subheader
      nav
      dense
    >
      <v-divider v-if="section" :key="`divider-${index}`"></v-divider>
      <v-subheader class="d-flex justify-start align-center">
        <v-checkbox
          @change="toggleSelectAll(index)"
          :input-value="selectAll[index]"
          :readonly="strict && selectAll[index]"
          :label="section.name"
          color="blue"
          dense
          hide-details
        >
          <v-icon @click="show(index)" slot="append">
            {{ showStudents == index ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon></v-checkbox
        >
      </v-subheader>
      <v-list-item-group
        v-show="showStudents == index"
        v-model="currentSelected"
        @change="$emit('selected', $event)"
        multiple
      >
        <v-list-item
          v-for="(student, index) in section.students"
          :key="`item-${index}`"
          :value="student.id"
          :disabled="strict && currentSelected.includes(student.id)"
          active-class="blue--text text--accent-3"
          dense
        >
          <template v-slot:default="{ active }">
            <v-checkbox
              :input-value="active"
              color="blue"
              dense
              hide-details
              class="mt-n1"
            ></v-checkbox>
            <v-list-item-content>
              {{ studentFullName(student) }}
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
export default {
  name: "StudentEnrolled",
  props: {
    selectedStudents: {
      type: Array,
      default: () => [],
    },
    studentsEnrolled: {
      type: Array,
      required: true,
    },
    strict: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showStudents: null,
      currentSelected: [],
    };
  },
  computed: {
    selectAll() {
      const currentSelected = this.currentSelected;
      let selectAll = this.studentsEnrolled.map((section) => {
        let areSelected = (student) => currentSelected.includes(student.id);
        return section.students.every(areSelected);
      });

      return selectAll;
    },
  },
  mounted() {
    if (this.selectedStudents?.length > 0) {
      this.currentSelected = this.selectedStudents;
    }
  },
  methods: {
    show(index) {
      if (this.showStudents === index) {
        this.showStudents = null;
      } else {
        this.showStudents = index;
      }
    },
    studentFullName(student) {
      return `${student.firstName} ${student.middleName || ""} ${
        student.lastName
      }`;
    },
    toggleSelectAll(index) {
      let selectedSection = this.studentsEnrolled[index].students,
        currentSelected = this.currentSelected,
        studentIds = selectedSection.map((student) => student.id);

      //filter for both toggled state to avoid duplicates
      currentSelected = currentSelected.filter(
        (studentId) => !studentIds.includes(studentId)
      );

      if (this.selectAll[index]) {
        //set toggled state to false
        this.selectAll[index] = false;
      } else {
        //set toggled state to false
        //push selection
        this.selectAll[index] = true;
        currentSelected = [...currentSelected, ...studentIds];
      }

      //set selected
      this.currentSelected = currentSelected;
      //emit to parent
      this.$emit("selected", currentSelected);
    },
  },
  watch: {
    selectedStudents: {
      handler(selected) {
        if (selected) {
          this.currentSelected = selected;
        }
      },
    },
  },
};
</script>

<template>
  <v-card class="elevation-2">
    <v-card-title
      class="d-block d-sm-flex justify-sm-start justify-sm-space-between align-center"
    >
      <h3 class="text-center">Assessments Bank</h3>
      <v-card-actions class="d-flex justify-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              class="px-4"
              light
              medium
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-plus-circle </v-icon>
              Create
            </v-btn>
          </template>
          <v-list dense tile nav class="pa-1">
            <v-list-item
              v-for="(item, index) in typeOptions"
              :key="index"
              :to="{ name: 'CreateAssessment', query: { type: item.value } }"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
    </v-card-title>
    <v-card-text
      class="d-sm-flex justify-sm-start justify-sm-space-between align-center"
    >
      <div class="mx-3 mx-sm-0 d-flex justify-start align-center">
        <v-select
          :items="typeOptions"
          :value="filterAssessmentsByType"
          @change="filterAssessmentsByType = $event"
          label="Filter by :"
          item-color="red"
          outlined
          dense
          flat
          hide-details
          style="max-width: 150px"
        ></v-select>
        <v-select
          class="pa-2 d-sm-none"
          :items="quarterOptions"
          :value="filterAssessmentsByQuarter"
          @change="filterAssessmentsByQuarter = $event"
          label="Quarter :"
          item-color="red"
          outlined
          dense
          flat
          hide-details
          style="max-width: 100px"
        ></v-select>
      </div>
      <SearchQuery
        @search="searchInputQuery = $event"
        style="max-width: 400px"
      />
    </v-card-text>
    <div class="d-none d-sm-flex align-sm-end">
      <v-btn-toggle
        v-model="filterAssessmentsByQuarter"
        :class="`main-${subject.color}-text`"
        mandatory
      >
        <v-btn
          v-for="quarter in quarterOptions"
          :key="quarter"
          :value="quarter"
          large
          text
        >
          Quarter {{ quarter }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-divider></v-divider>
    <v-card-text>
      <AssessmentList
        :subject="subject"
        :searchInput="searchInputQuery"
        :filterAssessmentsByQuarter="filterAssessmentsByQuarter"
        :filterAssessmentsByType="filterAssessmentsByType"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import SearchQuery from "@/components/SearchQuery";
import AssessmentList from "@/components/AssessmentList";
export default {
  metaInfo: {
    title: "Assessments",
  },
  name: "AssessmentIndex",
  components: {
    SearchQuery,
    AssessmentList,
  },
  data() {
    return {
      typeOptions: [
        { text: "Activity", value: "activity" },
        { text: "Quiz", value: "quiz" },
        { text: "Exam", value: "exam" },
      ],
      filterAssessmentsByType: "activity",
      filterAssessmentsByQuarter: "",
      searchInputQuery: "",
    };
  },
  computed: {
    subject() {
      return this.$store.state.subjectsModule.subject;
    },
    quarterOptions() {
      return this.$store.state.subjectsModule.quarterOptions;
    },
  },
  mounted() {
    const query = this.$route.query;
    if (query.type && query.quarter) {
      this.filterAssessmentsByType = query.type;
      this.filterAssessmentsByQuarter = query.quarter;
    }
  },
};
</script>
<style scoped>
.remove-toggled-state {
  color: white !important;
}
</style>

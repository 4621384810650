<template>
  <div>
    <v-dialog v-model="assignDialog" max-width="600px">
      <v-card v-if="selectedAssessment">
        <v-card-title
          :class="`main-${subject.color}-bg secondary-${subject.color}-text`"
        >
          Assign Assessment - {{ selectedAssessment.title }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="loadingStudents">
          <div class="py-4">
            <v-sheet color="grey lighten-4" class="pa-4" width="100%">
              <v-skeleton-loader
                class="mx-auto"
                type="list-item-two-line, divider, list-item, divider, list-item"
              ></v-skeleton-loader>
            </v-sheet>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <div v-if="studentsEnrolled.length > 0" class="py-4">
            <StudentEnrolled
              @selected="selectedStudents = $event"
              :selectedStudents="selectedStudents"
              :studentsEnrolled="studentsEnrolled"
              :strict="selectedAssessment.strict"
            />
          </div>
          <div v-else>
            <v-banner two-line>
              <v-icon slot="icon" color="info" size="36">
                mdi-information-outline
              </v-icon>
              No Students Found.
            </v-banner>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            @click="assignDialog = false"
            dark
            color="red accent-4"
            :ripple="false"
            >Close</v-btn
          >
          <v-btn
            :disabled="
              selectedAssessment.strict && selectedStudents.length === 0
            "
            :loading="assignLoading"
            @click="confirmAssignment(selectedAssessment, selectedStudents)"
            :dark="
              selectedAssessment.strict && selectedStudents.length === 0
                ? false
                : true
            "
            color="blue accent-4"
            :ripple="false"
            >Assign</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="activateDialog" max-width="400px">
      <v-card v-if="selectedAssessment">
        <v-card-title
          :class="`main-${subject.color}-bg secondary-${subject.color}-text`"
        >
          {{ selectedAssessment.status ? "Deactivate" : "Activate" }} Assessment
          - {{ selectedAssessment.title }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          {{
            selectedAssessment.status
              ? "Inactive assessments are not shown to students, "
              : "Active assessments are shown to students assigned to this assessment, "
          }}
          Are you sure you want to proceed?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            @click="activateDialog = false"
            dark
            color="red accent-4"
            :ripple="false"
            >Close</v-btn
          >
          <v-btn
            :loading="activateLoading"
            @click="
              confirmActivation(selectedAssessment, !selectedAssessment.status)
            "
            dark
            :color="`${selectedAssessment.status ? 'blue' : 'green'} accent-4`"
            :ripple="false"
            >{{ selectedAssessment.status ? "Deactivate" : "Activate" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="recordDialog" max-width="400px">
      <v-card v-if="selectedAssessment">
        <v-card-title
          :class="`main-${subject.color}-bg secondary-${subject.color}-text`"
        >
          Assessment - {{ selectedAssessment.title }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-switch
            @change="recorded = !recorded"
            :input-value="recorded"
            color="green"
            inset
            dense
            hide-details
            label="Recorded"
          />
          <v-expand-transition>
            <v-row
              v-if="recorded"
              class="ml-0 py-4"
              justify="start"
              justify-md="center"
            >
              <v-checkbox
                class="mr-3"
                @change="performanceTask = $event ? $event : false"
                :input-value="performanceTask"
                color="green"
                dense
                hide-details
                label="Performance Task"
              />
              <v-checkbox
                @change="writtenWork = $event ? $event : false"
                :input-value="writtenWork"
                color="green"
                dense
                hide-details
                label="Written Work"
              />
            </v-row>
          </v-expand-transition>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            @click="recordDialog = false"
            dark
            color="red accent-4"
            :ripple="false"
            >Close</v-btn
          >
          <v-btn
            :loading="recordLoading"
            @click="
              confirmRecord(
                selectedAssessment,
                recorded,
                performanceTask,
                writtenWork
              )
            "
            dark
            color="blue accent-4"
            :ripple="false"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="400px">
      <v-card v-if="selectedAssessment">
        <v-card-title
          :class="`main-${subject.color}-bg secondary-${subject.color}-text`"
        >
          Assessment - {{ selectedAssessment.title }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          Are you sure you want to delete assessment?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            @click="deleteDialog = false"
            dark
            color="red accent-4"
            :ripple="false"
            >Close</v-btn
          >
          <v-btn
            :loading="deleteLoading"
            @click="confirmDeletion(selectedAssessment)"
            dark
            color="blue accent-4"
            :ripple="false"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :search="searchInput"
      :headers="assessmentHeaders"
      :items="filteredAssessments"
      :loading="assessmentsLoading"
      :footer-props="{
        itemsPerPageOptions: assessmentsPerPage,
      }"
    >
      <template v-slot:[`item.title`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <td v-bind="attrs" v-on="on" class="text-center">{{item.title}}</td>
          </template>
          <span v-if="item.offline">Access Code : {{ item.accessCode }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.recorded`]="{ item }">
        <v-btn
          :color="`${item.recorded ? 'light-blue' : 'pink'} accent-4`"
          dark
          shaped
          small
          @click="recordAssessment(item)"
        >
          <v-icon size="15" left
            >mdi-notebook-{{
              item.recorded ? "edit-outline" : "remove-outline"
            }}</v-icon
          >
          {{ item.recorded ? "Recorded" : "Unrecorded" }}
        </v-btn>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-btn
          :color="`${item.status ? 'green' : 'red'} accent-4`"
          dark
          shaped
          small
          @click="activateAssessment(item)"
        >
          <v-icon size="15" left
            >mdi-{{ item.status ? "check" : "block-helper" }}</v-icon
          >
          {{ item.status ? "Active" : "Inactive" }}
        </v-btn>
      </template>
      <template v-slot:[`item.assigned`]="{ item }">
        <v-btn
          color="blue accent-4"
          dark
          shaped
          small
          @click="assignAssessment(item)"
        >
          <v-icon size="15" left>mdi-account-multiple-plus</v-icon>
          {{
            `${item.studentIds.length} student${
              item.studentIds.length > 1 ? "s" : ""
            }`
          }}
        </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn-toggle rounded>
          <v-btn
            class="remove-toggled-state"
            small
            color="green accent-4"
            :to="{
              name: 'ViewAssessment',
              params: { slug: subject.slug },
              query: { id: item.id },
            }"
          >
            <v-icon small color="white"> mdi-eye </v-icon>
          </v-btn>
          <v-btn
            :disabled="item.strict"
            class="remove-toggled-state"
            small
            color="blue accent-4"
            :to="{
              name: 'UpdateAssessment',
              params: { slug: subject.slug },
              query: { strict: item.strict, id: item.id },
            }"
          >
            <v-icon small color="white"> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            :disabled="item.strict"
            class="remove-toggled-state"
            small
            color="red accent-4"
            @click="deleteAssessment(item)"
          >
            <v-icon small color="white">mdi-delete</v-icon>
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StudentEnrolled from "@/components/StudentEnrolled";
export default {
  name: "AssessmentList",
  components: {
    StudentEnrolled,
  },
  props: {
    subject: {
      type: Object,
      required: true,
    },
    searchInput: {
      type: String,
      default: "",
    },
    filterAssessmentsByQuarter: {
      type: [Number, String],
      default: "",
    },
    filterAssessmentsByType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loadingStudents: false,
      assignDialog: false,
      assignLoading: false,
      activateDialog: false,
      activateLoading: false,
      recordDialog: false,
      recordLoading: false,
      recorded: false,
      performanceTask: false,
      writtenWork: false,
      deleteDialog: false,
      deleteLoading: false,
      studentsEnrolled: [],
      selectedStudents: [],
      selectedAssessment: null,
      assessmentsPerPage: [10, 15, 25, 50, 100],
      assessmentHeaders: [
        {
          text: "Assessment Title",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: "Total Score",
          align: "start",
          sortable: false,
          value: "totalScore",
        },
        {
          text: "Recorded",
          align: "center",
          sortable: false,
          value: "recorded",
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Currently Assigned",
          align: "start",
          sortable: false,
          value: "assigned",
        },
        {
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      assessments: (state) => state.assessmentModule.assessments,
      assessmentsLoading: (state) => state.assessmentModule.assessmentsLoading,
    }),
    filteredAssessments() {
      let filteredAssessments = this.assessments ? this.assessments : [];
      if (this.filterAssessmentsByQuarter) {
        filteredAssessments = filteredAssessments?.filter(
          (assessment) => assessment.quarter == this.filterAssessmentsByQuarter
        );
      }

      if (this.filterAssessmentsByType) {
        filteredAssessments = filteredAssessments?.filter(
          (assessment) => assessment.type == this.filterAssessmentsByType
        );
      }

      return filteredAssessments;
    },
  },
  async mounted() {
    const subjectId = this.subject.id;
    await this.$store.dispatch("assessmentModule/getAssessments", subjectId);
  },
  methods: {
    async confirmDeletion(assessment) {
      this.deleteLoading = true;
      let payload = {
        assessmentId: assessment.id,
        subjectId: assessment.subjectId,
      };

      if (!assessment.strict) {
        await this.$store.dispatch(
          "assessmentModule/deleteAssessment",
          payload
        );
      }

      this.deleteLoading = false;
      this.deleteDialog = false;
    },
    deleteAssessment(assessment) {
      this.selectedAssessment = assessment;
      this.deleteDialog = true;
      this.deleteLoading = false;
    },
    async confirmRecord(assessment, recorded, performanceTask, writtenWork) {
      this.recordLoading = true;
      if (
        assessment.recorded !== recorded ||
        assessment.performanceTask !== performanceTask ||
        assessment.writtenWork !== writtenWork
      ) {
        let payload = {
          assessmentId: assessment.id,
          subjectId: assessment.subjectId,
          recorded,
          performanceTask,
          writtenWork,
        };
        await this.$store.dispatch(
          "assessmentModule/recordAssessment",
          payload
        );
      }

      this.recordLoading = false;
      this.recordDialog = false;
    },
    recordAssessment(assessment) {
      this.selectedAssessment = assessment;
      this.recorded = assessment.recorded;
      this.performanceTask = assessment.performanceTask;
      this.writtenWork = assessment.writtenWork;
      this.recordDialog = true;
      this.recordLoading = false;
    },
    async confirmActivation(assessment, status) {
      this.activateLoading = true;
      let payload = {
        assessmentId: assessment.id,
        subjectId: assessment.subjectId,
        status,
      };
      await this.$store.dispatch(
        "assessmentModule/activateAssessment",
        payload
      );

      this.activateLoading = false;
      this.activateDialog = false;
    },
    activateAssessment(assessment) {
      this.selectedAssessment = assessment;
      this.activateDialog = true;
      this.activateLoading = false;
    },
    async confirmAssignment(assessment, studentIds) {
      this.assignLoading = true;
      let payload = {
        assessmentId: assessment.id,
        subjectId: assessment.subjectId,
        studentIds,
      };
      await this.$store.dispatch(
        "assessmentModule/assignAssessmentToStudents",
        payload
      );

      this.assignLoading = false;
      this.assignDialog = false;
    },
    async assignAssessment(assessment) {
      this.selectedAssessment = assessment;
      this.selectedStudents = assessment.studentIds;
      this.assignDialog = true;
      this.assignLoading = false;
      await this.getStudents(this.subject.id);
    },
    async getStudents(subjectId) {
      this.loadingStudents = true;
      if (this.studentsEnrolled.length === 0) {
        await this.$store.dispatch("_subjectsModule/getSubjectById", subjectId);
        this.studentsEnrolled =
          this.$store.getters["_subjectsModule/getStudentsEnrolled"];
      }

      this.loadingStudents = false;
    },
  },
};
</script>
<style scoped>
.remove-toggled-state {
  color: white !important;
}
</style>
